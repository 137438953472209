<template>
  <b-card no-body class="mb-4 p-3">
    <div>
      <h4 class="card-title">{{ tabela.quantidadeItens }} registros</h4>
    </div>
    <div
      class="filtro-valorem d-flex align-items-center justify-content-between mb-2"
    >
      <input-text
        id="input-filtro"
        no-label
        :placeholder="$t('PLACEHOLDER.FILTRAR_ESTABELECIMENTO')"
        v-model="filtro"
        @blur="filtrar"
        class="w-50"
      />
      <b-button
        id="btn-novo"
        @click="novo"
        v-if="permissao.criar"
        variant="primary"
      >
        {{ $t("ESTABELECIMENTOS.NOVO_ESTABELECIMENTO") }}
      </b-button>
    </div>

    <div class="d-flex align-items-center mb-2">
      <b-button
        id="btn-editar"
        @click="editar(null)"
        v-if="dadosSelecionados.length == 1"
        class="mr-2"
        variant="outline-primary"
      >
        {{ $t("CLIENTES.EDITAR") }}
      </b-button>
      <b-button
        id="btn-alterar-situacao"
        @click="ativarInativar(botaoAtivarInativar)"
        v-if="dadosSelecionados.length > 0 && permissao.editar"
        variant="outline-primary"
      >
        {{
          `${
            !botaoAtivarInativar
              ? $t("CLIENTES.ATIVAR")
              : $t("CLIENTES.INATIVAR")
          }`
        }}
      </b-button>
    </div>
    <valorem-tabela
      id="tabela"
      sem-selecionar-todos
      :metodo-validacao-selecionar="metodoValidacaoSelecionar"
      v-model="tabela.dados"
      :colunas="tabela.colunas"
      :quantidade-itens="tabela.quantidadeItens"
      ref="tabela"
      backend-paginado
      @paginar="listar"
    >
      <template #cell(ativo)="dados">
        <span
          :class="`text-${
            retornarVariante(dados.value).variante
          } text-capitalize`"
        >
          {{ retornarVariante(dados.value).text }}
        </span>
      </template>
    </valorem-tabela>
  </b-card>
</template>

<script>
import EstabelecimentoService from "@/common/services/estabelecimentos/estabelecimentos.service";
import { START_LOADING, STOP_LOADING } from "@/store/Store";
import { AlertasMixin } from "@/common/mixins/AlertasMixin";
import helpers from "@/common/utils/helpers";
import { DEFINIR_BREADCRUMB } from "@/store/breadcrumbs.module";
import InputText from "@/components/inputs/InputText";

export default {
  mixins: [AlertasMixin],
  name: "Index",
  components: {
    InputText,
  },
  data() {
    return {
      filtro: "",
      tabela: {
        dados: [],
        colunas: [
          { key: "seletor", label: "" },
          {
            key: "razaoSocial",
            label: this.$t("ESTABELECIMENTOS.RAZAO_SOCIAL"),
          },
          {
            key: "nomeFantasia",
            label: this.$t("ESTABELECIMENTOS.NOME_FANTASIA"),
          },
          {
            key: "cpfCnpj",
            label: this.$t("ESTABELECIMENTOS.CPF_CNPJ"),
            formatter: (v) => {
              if (v) return helpers.formatarDocumento(v);
            },
          },
          {
            key: "dataCriacao",
            label: this.$t("GERAL.DT_CADASTRO"),
            formatter: (v) => {
              if (v) return helpers.formatarDataBr(v);
            },
          },
          {
            key: "eSitefMerchantId",
            label: this.$t("ESTABELECIMENTOS.MERCHANTID"),
          },
          { key: "ativo", label: this.$t("GERAL.STATUS") },
        ],
        quantidadeItens: 0,
        paginaAtual: 0,
        porPagina: 0,
      },
      botaoAtivarInativar: null,
    };
  },
  mounted() {
    this.$store.dispatch(DEFINIR_BREADCRUMB, [
      { titulo: this.$t("MENU.ESTABELECIMENTOS") },
    ]);
    this.listar();
  },
  methods: {
    filtrar: function () {
      this.$refs.tabela.limpar();
      this.listar();
    },
    listar: function (paginaAtual = 0, porPagina = 10) {
      this.$store.dispatch(START_LOADING);
      EstabelecimentoService.listar(this.filtro, paginaAtual, porPagina)
        .then((res) => {
          this.tabela.dados = res.data.data.itens;
          this.paginaAtual = paginaAtual;
          this.porPagina = porPagina;
          this.tabela.quantidadeItens =
            res.data.data.paginacao.totalDeElementos;
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    novo: function () {
      this.$router.push({ name: "estabelecimentos-novo" });
    },
    editar: function (id) {
      if (!id) {
        this.$router.push({
          name: "estabelecimentos-editar",
          params: { id: this.dadosSelecionados[0].id },
        });
      } else {
        this.$router.push({
          name: "estabelecimentos-editar",
          params: { id: id },
        });
      }
    },
    ativarInativar: function (ativarInativar) {
      this.confirmar(
        this.$t(
          `ESTABELECIMENTOS.TITULO_${!ativarInativar ? "ATIVAR" : "INATIVAR"}`
        ),
        this.$t(
          `ESTABELECIMENTOS.CONFIRMACAO_${
            !ativarInativar ? "ATIVAR" : "INATIVAR"
          }`
        ),
        this.$t(
          `ESTABELECIMENTOS.BOTAO_${!ativarInativar ? "ATIVAR" : "INATIVAR"}`
        ),
        this.$t(`GERAL.CANCELAR`)
      ).then((confirmado) => {
        if (!confirmado) return;
        this.$store.dispatch(START_LOADING);
        EstabelecimentoService.ativacaoEstabelecimento(
          { estabelecimentosId: this.dadosSelecionados.map((el) => el.id) },
          !ativarInativar
        )
          .then(() => {
            this.toastSucesso(
              this.$t(
                `ESTABELECIMENTOS.${
                  !ativarInativar ? "ATIVAR" : "INATIVAR"
                }_SUCESSO`
              )
            );
            this.listar(this.paginaAtual, this.porPagina);
          })
          .finally(() => {
            this.$store.dispatch(STOP_LOADING);
          });
      });
    },
    excluir: function () {
      this.confirmar("", this.$t("GERAL.MENSAGEM_CONFIRMAR_ACAO")).then(
        (confirmado) => {
          if (!confirmado) return;
          this.$store.dispatch(START_LOADING);
          EstabelecimentoService.excluir(
            this.dadosSelecionados.map((el) => el.id)
          )
            .then(this.listar())
            .finally(() => {
              this.$store.dispatch(STOP_LOADING);
            });
        }
      );
    },
    retornarVariante: function (situacao) {
      return helpers.Situacao.find((el) => el.value == situacao);
    },
    metodoValidacaoSelecionar: function (item) {
      if (this.botaoAtivarInativar == null) return true;
      if (item.ativo != this.botaoAtivarInativar) return false;
      return true;
    },
  },
  computed: {
    dadosSelecionados: function () {
      return this.tabela.dados.filter((el) => {
        return el.selecionado;
      });
    },
    permissao: function () {
      return helpers.validarAcesso("Estabelecimento");
    },
  },
  watch: {
    dadosSelecionados: function () {
      if (this.dadosSelecionados.length == 1) {
        this.botaoAtivarInativar = this.dadosSelecionados[0].ativo;
      } else if (this.dadosSelecionados.length == 0) {
        this.botaoAtivarInativar = null;
      }
    },
  },
};
</script>
