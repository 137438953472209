import ApiService from "@/common/api/api.service";

const EstabelecimentoService = {
  listar: function (filtro, NumeroDaPagina = 0, TamanhoDaPagina = 10) {
    let url = `estabelecimento?NumeroDaPagina=${NumeroDaPagina}&TamanhoDaPagina=${TamanhoDaPagina}`;
    url = url.concat(`${filtro ? `&TermoPesquisa=${filtro}` : ''}`);
    return ApiService.get(url);
  },
  salvar: function (form) {
    if (form.id) {
      return ApiService.put("estabelecimento", form);
    }
    return ApiService.post("estabelecimento", form);
  },
  excluir: function (form) {
    return ApiService.delete(`estabelecimento`, form);
  },
  buscar: function (id) {
    return ApiService.get(`estabelecimento/${id}`);
  },
  buscarPorUsuario: function (userId) {
    return ApiService.get(`estabelecimento/ativos-por-usuario/${userId}`);
  },
  ativacaoEstabelecimento: function (form, ativarInativar) {
    if (ativarInativar) {
      return ApiService.put(`estabelecimento/ativar`, form);
    }
    return ApiService.put(`estabelecimento/inativar`, form);
  },
  ativacaoContaBancaria: function (form, ativarInativar) {
    if (!form.contaBancariaId) {
      return new Promise((resolve) => { resolve() });
    }
    if (ativarInativar) {
      return ApiService.put(`conta-bancaria/ativar`, form);
    }
    return ApiService.put(`conta-bancaria/inativar`, form);
  },
  ativacaoContato: function (form, ativarInativar) {
    if (ativarInativar) {
      return ApiService.put(`contato/ativar`, form);
    }
    return ApiService.put(`contato/inativar`, form);
  },
  gerarToken: async function (id) {
    return await ApiService.put(`conta-bancaria/gerar-token`, {
      contaBancariaId: id,
    });
  },
};

export default EstabelecimentoService;
